import Vue from 'vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import ItemSearchSelector from '~/components/item-search-selector.vue';
import { roles } from '#/role';
import { mdiEmail } from '@mdi/js';
export default {
    name: 'user-fields',
    components: { ValidationObserver, ValidationProvider, ItemSearchSelector },
    props: {
        user: {
            type: Object,
        },
        canChangeUserRole: {
            default: false
        },
        canChangeUserOrganization: {
            default: false
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
    },
    model: {
        prop: 'user',
    },
    data() {
        return {
            mdiEmail,
            roles,
        };
    },
    methods: {
        async organizationChanged(id, resource) {
            this.user.organizationType = resource;
            this.user.organization = id;
            await Vue.nextTick();
            this.$refs.observer.validate();
        },
    }
};
