var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.user)?_c('div',{staticClass:"user-fields"},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
return [_c('v-row',{staticClass:"pb-8"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{ref:"firstName",attrs:{"name":"firstName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"firstName","label":_vm.$t('First name'),"error-messages":errors,"outlined":"","disabled":_vm.readOnly},on:{"input":validate},model:{value:(_vm.user.firstName),callback:function ($$v) {_vm.$set(_vm.user, "firstName", $$v)},expression:"user.firstName"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{ref:"lastName",attrs:{"name":"lastName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"lastName","label":_vm.$t('Last name'),"error-messages":errors,"outlined":"","disabled":_vm.readOnly},on:{"input":validate},model:{value:(_vm.user.lastName),callback:function ($$v) {_vm.$set(_vm.user, "lastName", $$v)},expression:"user.lastName"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{ref:"email",attrs:{"name":"email","rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"email","label":_vm.$t('E-mail'),"prepend-inner-icon":_vm.mdiEmail,"error-messages":errors,"autocapitalize":"none","autocomplete":"off","outlined":"","disabled":_vm.readOnly},on:{"input":validate},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{ref:"role",attrs:{"name":"role","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"name":"role","items":_vm.roles,"label":_vm.$t('Role'),"error-messages":errors,"outlined":"","disabled":!_vm.canChangeUserRole || _vm.readOnly},on:{"input":validate},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("roles." + item)))+" ")]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("roles." + item)))+" ")]}}],null,true),model:{value:(_vm.user.role),callback:function ($$v) {_vm.$set(_vm.user, "role", $$v)},expression:"user.role"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{ref:"organization",attrs:{"name":"organization"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('item-search-selector',{attrs:{"name":"organization","resources":['agencies', 'services'],"selectedResource":_vm.user.organizationType,"label":_vm.$t('Organization'),"item-text":function (item) { return item.name; },"error-messages":errors,"disabled":!_vm.canChangeUserOrganization || _vm.readOnly,"showResourceTypeInList":true,"hide-details":"","no-filter":"","clearable":"","outlined":""},on:{"input":_vm.organizationChanged},model:{value:(_vm.user.organization),callback:function ($$v) {_vm.$set(_vm.user, "organization", $$v)},expression:"user.organization"}})]}}],null,true)})],1)],1),_c('v-divider'),_c('v-row',{staticClass:"pt-8"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{ref:"userId",attrs:{"name":"userId"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"userId","label":_vm.$t('userId'),"error-messages":errors,"outlined":"","disabled":_vm.readOnly},on:{"input":validate},model:{value:(_vm.user.userId),callback:function ($$v) {_vm.$set(_vm.user, "userId", $$v)},expression:"user.userId"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{ref:"LuxTrustSN20",attrs:{"name":"LuxTrustSN20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"LuxTrustSN20","label":_vm.$t('LuxTrustSN20'),"error-messages":errors,"outlined":"","disabled":_vm.readOnly},on:{"input":validate},model:{value:(_vm.user.LuxTrustSN20),callback:function ($$v) {_vm.$set(_vm.user, "LuxTrustSN20", $$v)},expression:"user.LuxTrustSN20"}})]}}],null,true)})],1)],1)]}}],null,false,1112654931)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }