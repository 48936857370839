import λ from '~/utils/invoker';
import FormMixin from '~/mixins/form';
import UserFields from '~/users/user-fields.vue';
import GoBackWrapper from '~/components/go-back-wrapper.vue';
import PermissionMixin from '~/mixins/permission';
import { ValidationObserver } from 'vee-validate';
import { displayName } from '#/user';
import { mdiContentSaveOutline } from '@mdi/js';
const usersλ = λ.namespaced('users');
export default {
    name: 'users-form',
    mixins: [FormMixin('users'), PermissionMixin],
    components: {
        ValidationObserver,
        GoBackWrapper,
        UserFields,
    },
    props: {
        id: {
            type: String,
        },
    },
    data() {
        return {
            mdiContentSaveOutline,
        };
    },
    async created() {
        await this.prepareForm(this.id);
    },
    computed: {
        item: usersλ.state('editing'),
        canList: λ.self('canListUsers'),
        canSave() {
            return this.canUpdateUsers || this.canUpdateOwnUser(this.id);
        },
    },
    methods: {
        displayName,
    },
};
