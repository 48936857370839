import λ from '~/utils/invoker';
import { routeNames } from '~/router';
import { clone, equals, not } from 'ramda';
export default function formMixin(resource) {
    const resourceλ = λ.namespaced(resource);
    return {
        data() {
            return {
                originalItem: null,
            };
        },
        computed: {
            item: resourceλ.state('editing'),
            itemAltered() {
                return !equals(this.originalItem, this.item);
            },
        },
        methods: {
            createNew: resourceλ.action('createNew'),
            fetchEdit: resourceλ.action('fetchEdit'),
            saveEdit: resourceλ.action('saveEdit'),
            cancelEdit: resourceλ.mutation('cancelEdit'),
            refreshEdit: resourceλ.mutation('refreshEdit'),
            async prepareForm(id) {
                if (id) {
                    await this.fetchEdit(id);
                }
                else {
                    this.refreshEdit(await this.createNew());
                }
                this.originalItem = clone(this.item);
            },
            async saveItem() {
                const savedItem = await this.saveEdit();
                if (savedItem) {
                    this.originalItem = clone(this.item);
                    if (this.canList) {
                        this.$router.push({
                            name: routeNames[resource].list,
                        });
                    }
                }
            },
        },
        beforeRouteLeave(to, from, next) {
            const leavingForm = not(RegExp(`/${resource}/`).test(to.path));
            if (leavingForm) {
                if (this.itemAltered) {
                    return this.$globalConfirmDialog.open({
                        title: this.$t('You have unsaved data on this page'),
                        text: this.$t('There is unsaved data on this page. Do you want to return to the page to save the data, or do you want to continue without saving?'),
                        confirmLabel: this.$t('Continue without saving'),
                        cancelLabel: this.$t('Cancel and return to form'),
                        maxWidth: '800px',
                    })
                        .then(dialogResult => {
                        if (dialogResult) {
                            this.cancelEdit();
                            return next();
                        }
                        return next(false);
                    })
                        .catch(() => next(false));
                }
                this.cancelEdit();
            }
            else {
                this.prepareForm(to.params.id);
            }
            next();
        },
    };
}
;
